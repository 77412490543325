(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name informacio.people.controller:PeopleCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.entitats.entitat.informacio.people')
  .controller('EntitatPeopleCtrl', EntitatPeopleCtrl);

  function EntitatPeopleCtrl(users) {
    var vm = this;
    vm.ctrlName = 'EntitatPeopleCtrl';
    vm.users = users;
  }
}());
